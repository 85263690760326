.section-heading {
	font-size: 2.5rem;
}

.section-intro {
	font-size: 1.25rem;
}

.hero-section {
	.headline {
		font-size: 2.5rem;
		font-weight: bold;
	}
	.subheadline {
		font-size: 1.25rem;
	}

}

.hero-quotes {
	.quote {
		position: relative;
		border-left: 4px solid lighten($theme-color-primary, 20%);
		font-style: italic;

		&:after {
			content: "";
		    display: block;
		    width: 0;
		    height: 0;
		    border-left: 10px solid transparent;
		    border-right: 10px solid transparent;
		    border-top: 10px solid $theme-bg-light;
		    position: absolute;
		    bottom: -10px;
		    margin-left: -10px;
		    left: 2rem;
		}

	}
	.source {
	
		vertical-align:middle;
		color: $theme-text-color-light;
		font-size: 0.875rem;
		
	}
	.source-profile {
		width: 60px;
		height: 60px;
		
	}
	.carousel-indicators {
		bottom: -4rem;
		li {
			border: none;
		    background-color: $theme-text-color-secondary;
		    width: 10px;
		    height: 10px;
		    @include border-radius(50%);
		    margin-left: 5px;
		    margin-right: 5px;
		}
		
	}
}

.book-cover-holder {
	position:relative;
	.book-badge {
		position: absolute;
		right: 0;
		top: -2rem;
		width: 120px;
		height: 120px;
		background: $theme-color-badge;
		@include border-radius(50%);
		text-align: center;
		color: #fff;
		font-weight: bold;
		font-family: 'Quicksand', sans-serif;
		padding-top: 34px;
		font-size: 1.375rem;
		line-height: 1;
		font-weight: bold;
	}
}

.benefits-section {
	.item-inner {
		height: 100%;
	}
	.item-icon {
		font-size: 3rem;
		color: $theme-color-primary;
	}
	.item-heading {
		font-size: 1.125rem;
	}
}

.content-section {
	.key-points-list {
		font-size: 1.125rem;
		
		li {
			margin-bottom: 1rem;
		}
	}
	
	.fa-check-circle {
		color: $theme-color-tick;
	}
}


.audience-section {
	.audience {
		max-width: 500px;
	}
	.item {
		margin-bottom: 1.5rem;
	}
	.item-title {
		font-size: 1.125rem;
	}
	.item-icon {
		font-size: 1.25rem;
		color: $theme-color-tick;
	}
}

.reviews-section {

	.item-inner {
		height: 100%;
		position: relative;
	}
	
	.icon-holder {
		position: absolute;
		left: 50%;
		top: -18px;
		margin-left: -18px;
		display: inline-block;
		width: 36px;
		height: 36px;
		background: lighten($theme-color-primary, 20%);
		color: #fff;
		text-align: center;
		@include border-radius(50%);
		padding-top: 6px;
	}
	.source-profile {
		width: 60px;
	}
	.source-info {
		font-size: 0.875rem;
	}
	.quote {
		font-style:italic;
		
	}
}

.author-section {
	color: #fff;
	
	.author-pic {
		width: 150px;
	    height: 150px;
	}
	.social-list {
		li {
			background: rgba(0, 0, 0, 0.1);
			width: 36px;
			height: 36px;
			display: inline-block;
			@include border-radius(50%);
			padding-top: 6px;
			&:hover {
				background: rgba(0, 0, 0, 0.15);
			}
		}
		a {
			color: #fff;
		}
	}
}

.theme-bg-primary {
	a.theme-link {
		color: #fff;
		text-decoration: underline;
	    -webkit-text-decoration-color: rgba(256, 256, 256, 0.5);
        text-decoration-color: rgba(256, 256, 256, 0.5);
		&:hover {
			color: #fff;
			-webkit-text-decoration-color: #fff;
	        text-decoration-color: #fff;
		}
	}
}



/* ===== Responsive ======== */
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.hero-quotes .quote:after {
		left: 50%;
		margin-left: -10px;
	}
	.reviews-section .source-profile {
		margin-bottom: 0.5rem;
	}
	.form-inline .form-control {
	    width: 200px;
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
	.section-heading {
		font-size: 1.75rem;
	}
	
	.section-intro {
		font-size: 1.125rem;
	}
	
	.hero-section {
		.headline {
			font-size: 2rem;
			font-weight: bold;
		}
		.subheadline {
			font-size: 1.125rem;
		}
	
	}
	.book-cover-holder .book-badge {
		width: 100px;
		height: 100px;
		padding-top: 25px;
		font-size: 1.25rem;
		
	}
}


@media (min-width: 576px) {
	.form-inline .form-control {
	    width: 400px;
	}
}

