/* ============= Base ============= */

/*********** Theme Generic **********/
body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $theme-text-color-secondary;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Quicksand', sans-serif;
	color: $theme-text-color-primary;
	font-weight: bold;
}


a.theme-link {
	color: $theme-text-color-primary;
	text-decoration: underline;
    -webkit-text-decoration-color: rgba($theme-text-color-primary,0.3);
    text-decoration-color: rgba($theme-text-color-primary,0.3);
    &:hover {
	    color: $theme-color-primary;
	    -webkit-text-decoration-color: rgba($theme-color-primary,0.8);
	        text-decoration-color: rgba($theme-color-primary,0.8);
    }
}

.btn {
	font-weight: 600;
	padding: 0.875rem 2.5rem;
	@include transition (all 0.4s ease-in-out);
	@include border-radius(2rem);
	border: none;
	
	&:hover, &:active, &:focus {
		box-shadow: 0 10px 20px -4px rgba(0,0,0,0.05);
	}
}

.btn-submit {
	@include border-radius(0.25rem);
	padding: 0.875rem 1.5rem;
	
}

.btn:focus, .btn.focus {
	box-shadow: none !important;
}

.btn-primary {
   background: $theme-color-primary;
   &:hover, &:active, &:focus {
		background: darken($theme-color-primary, 5%);
	}
}

.btn-secondary {
	background: white;
	border: 2px solid $theme-color-primary;
	color: $theme-color-primary;
	&:hover, , &:active, &:focus, &:not(:disabled):not(.disabled):active {
		background: $theme-color-primary;
		border: 2px solid $theme-color-primary;
		color: white;
	}
}

.btn-light {
	background: $theme-bg-light;
	color: $theme-color-primary;
	&:hover {
		background: $theme-bg-light;
		color: $theme-color-primary;
	}
}


.form-control {
	padding-top: 0.875rem;
	padding-bottom: 0.875rem;
	height: 56px;
	border-color: darken($theme-bg-light, 5%);
	&:focus {
		box-shadow: 0 5px 15px 0 rgba(0,0,0,0.15);
		outline: none;
	}
}


.theme-bg-light {
	background: $theme-bg-light;
}

.theme-bg-dark {
	background: $theme-bg-dark;
}

.theme-bg-primary {
	background: $theme-bg-primary;
}


.theme-bg-light-gradient {
	@include gradient-y($theme-bg-light, lighten($theme-bg-light, 10%), $start-percent: 0%, $end-percent: 70%);
}



#topcontrol {
    @include transition (all 0.4s ease-in-out);
    background: $theme-text-color-primary;
    color: #fff;
    text-align: center;
    display: inline-block;
    z-index: 30;
    width: 2.5rem;
    height: 2.5rem;
    @include border-radius(50%);
    @include box-shadow(0 0.125rem 0.25rem rgba(0,0,0,0.075));
    padding-top: 0.5rem;
    font-weight: 300;
    font-size: 1rem;
    
    &:hover {
        background: lighten($theme-text-color-primary, 10%);
        color: #fff;
    }

}


/*********** Theme Utilities **********/
.border-radius-0 {
	@include border-radius(0);
}


.font-style-normal {
	font-style: normal !important;
}


.single-col-max {
	max-width: $single-col-max;
}


/* Header */
.site-logo {
    .logo-text {
	    //font-family: 'Anton', sans-serif;
	    color: $theme-text-color-primary;
	    font-size: 1.5rem;
	    font-weight: bold;
    }
}


/* Footer */
.footer {

	.copyright {
		font-size: 0.875rem;
	}
	
	
}


