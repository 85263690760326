/*************************** Theme Colours****************************/
$theme-color-primary: #1d9084;

$theme-text-color-primary: #1c1e2e;
$theme-text-color-secondary: lighten($theme-text-color-primary, 25%);
$theme-text-color-light: lighten($theme-text-color-primary, 40%);

$theme-bg-light: #f6f8fc;
$theme-bg-primary: $theme-color-primary;
$theme-bg-dark: $theme-text-color-primary;

$theme-border-color:  #efefef;
$theme-divider-color: #efefef;

$theme-divider: lighten($theme-text-color-primary, 75%);

$smoky-white: #f5f5f5;

$single-col-max: 760px;

$theme-color-tick: #1d9084;
$theme-color-badge: #9C27B0;




/*************************** Variables Section. *****************************/

$gray-100: lighten($theme-text-color-secondary, 65%);
$gray-200: lighten($theme-text-color-secondary, 55%);
$gray-300: lighten($theme-text-color-secondary, 50%);
$gray-400: lighten($theme-text-color-secondary, 45%);
$gray-500: lighten($theme-text-color-secondary, 40%);
$gray-600: lighten($theme-text-color-secondary, 30%);
$gray-700: lighten($theme-text-color-secondary, 20%);
$gray-800: lighten($theme-text-color-secondary, 10%);
$gray-900: $theme-text-color-primary;

$theme-colors: (
  "primary":  $theme-color-primary,
  "secondary": $theme-text-color-secondary,
);

/*************************** Import Bootstrap  *****************************/
@import "~bootstrap";


/*************************** Theme Styles Section ****************************/
@import "theme/styles.scss";

